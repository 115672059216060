import React from 'react'
import companyLogo from 'src/images/logo.png';
import Link from 'components/foundations/Link/Link'
import 'src/app.css'

export default function Logo() {
    return (
    <div class="logoContainer">
      <Link to="/">
        <img src={companyLogo} alt="Loud Minimalism logo"/>
      </Link>
    </div>
  )
}
